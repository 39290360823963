.product-image{
    width: 100px;
    height: 100px;
    overflow: hidden;
    text-align: center;
}
.product-image img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.productListContainer {
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-left: 1px solid rgba(0, 0, 0, 0.158);
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  #productListHeading {
    font: 400 2rem "Roboto";
    padding: 0.5vmax;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.637);
    transition: all 0.5s;
    margin: 2rem;
    margin-top: 0%;
    text-align: center;
  }
  
  .productListTable {
    background-color: white;
    border: none !important;
  }
  
  .productListTable div {
    font: 300 1vmax "Roboto";
    color: rgba(0, 0, 0, 0.678);
    border: none !important;
  }
  
  .productListTable a,
  .productListTable button {
    color: rgba(0, 0, 0, 0.527);
    transition: all 0.5s;
  }
  
  .productListTable a:hover {
    color: tomato;
  }
  
  .productListTable button:hover {
    color: rgb(236, 30, 30);
  }
  
  .MuiDataGrid-columnHeader div {
    /* color: rgb(255, 255, 255); */
  }
  
  @media screen and (max-width: 600px) {
    .productListTable div {
      font: 300 4vw "Roboto";
    }
  }
  