  .dashboardSummary {
    margin: 2rem 0;
  }
  
  .dashboardSummary > div {
    display: flex;
    background-color: white;
    justify-content: center;
  }
  /* .dashboardSummary > div > p {
    background-color: rgba(70, 117, 218, 0.932);
    color: white;
    font: 300 1.3rem "Roboto";
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    margin: 0 2rem;
  } */
  .dashboardSummaryBox2 > a {
    color: rgb(249 249 249);
    background-color: rgb(46 54 80);
    /* color: rgb(0, 0, 0); */
    /* background-color: rgb(255, 233, 174); */
    font: 300 2rem "Roboto";
    text-align: center;
    text-decoration: none;
    /* padding: 1.5rem; */
    padding: 4rem;
    /* width: 10vmax;
    height: 10vmax; */
    margin: 2rem;
    /* border-radius: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .para{
    margin-top: 2px;
  }
  
  /* .dashboardSummaryBox2 > a:first-child {
    background-color: rgb(255, 110, 110);
    color: rgb(255, 255, 255);
  }
  
  .dashboardSummaryBox2 > a:last-child {
    background-color: rgb(51, 51, 51);
    color: rgb(255, 255, 255);
  } */
  
  @media screen and (max-width: 600px) {

  
    .dashboardSummary > div > p {
      margin: 0;
    }
  
    .dashboardSummaryBox2 > a {
      /* padding: 0.5rem; */
      margin: 1rem;
      font: 300 0.9rem "Roboto";
    }
  }
  